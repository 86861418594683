import * as React from 'react';

import useStrings from "src/localization/Localization";
import { FromStore } from "src/helpers/Store";
import { DeclareState } from "src/helpers/State";
import { useDispatch } from "react-redux";

import { Box, IconButton, styled } from '@mui/material';

import ButtonCustom from './ButtonCustom';
import { ActiveProductOnShoppingList, AddProductsOnShoppingList, RemoveProductOnShoppingList } from 'src/stores/shoppingList/action';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { capitalizeFirstLetter } from 'src/helpers/fonctions';
import Badge from '@mui/material/Badge';

type Food = {
    id: number,
    name: string,
    quantity: number,
    is_in_shopping_list: boolean,
    is_manually_added: boolean,
}

type CategorieShoppingList = {
    title: string,
    foods: Food[]
}

type CardShoppingListProps = {
    id: number,
    title?: string,
    elements: CategorieShoppingList[]
}

const CardShoppingList: React.FunctionComponent<CardShoppingListProps> = props => {

    const _strings = useStrings();

    const _shopping = FromStore(state => state.shopping_list[props.id]);
    const [inputList, setInputList] = React.useState(undefined as any);
    const [isInShoppingList, setIsInShoppingList] = React.useState([] as boolean[]);
    const _dispatch = useDispatch();

    const [addItem, setAddItem] = React.useState([] as any[]);
    // const [newIdFood, setNewIdFood] = React.useState(_shopping && _shopping.foodsManually ? Object.values(_shopping.foodsManually).length : 0);
    const [newIdFood, setNewIdFood] = React.useState(_shopping && _shopping.foodsManually ? lastIdInObject(_shopping.foodsManually) + 1 : 0);
    const _state = DeclareState({
        shopping_list: _shopping
    });

    const barredStyle = {
        textDecoration: "line-through",
    }
    const normalStyle = {
        textDecoration: "none",
    }

    const handleClickActiveCard = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let foodId = String(event.currentTarget.dataset.foodid);

        let element = document.getElementById(foodId);
        if (element) {
            let tmp = isInShoppingList;
            tmp[foodId] = !tmp[foodId];
            setIsInShoppingList(tmp);

            _dispatch(ActiveProductOnShoppingList(props.id, Number(foodId)));
        }
    }

    const handleClickAddProduct = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let length = 0;
        if (addItem.length == 0) {
            length = _shopping.foodsManually.length ? _shopping.foodsManually.length + addItem.length : 0;
        } else {
            length = _shopping.foodsManually.length ? _shopping.foodsManually.length : 0;
        }
        setNewIdFood(newIdFood + 1);
        // let length = addItem.length == 0 ? _state.content.shopping_list.foodsManually.length + addItem.length : _state.content.shopping_list.foodsManually.length | 0;
        let input = <ItemFood key={length} id={newIdFood} food={{ is_manually_added: true }} />;
        setInputList(input);
    }

    const handleKeyDown = (event: React.KeyboardEvent | React.FocusEvent) => {
        // if ( (event.type == "keydown" && event.key === 'Enter') || event.type == 'blur') {}
        if (event.type == 'blur') {

            const target = event.target as HTMLInputElement;
            let id = Number(target.id);
            let value = target.value;

            let items = addItem;
            if (items[props.id] && items[props.id].foodsManually) {

                let index = isInObject(items[props.id].foodsManually, id);
                if (index != -1) {
                    items[props.id].foodsManually[index] =
                        {
                            id: id,
                            name: value,
                            quantity: 1,
                            is_in_shopping_list: true,
                            is_manually_added: true,
                        } as Food;
                } else {
                    items[props.id].foodsManually.push(
                        {
                            id: id,
                            name: value,
                            quantity: 1,
                            is_in_shopping_list: true,
                            is_manually_added: true,
                        }
                    )
                }
            } else {
                items[props.id] = {
                    foodsManually: [
                        {
                            id: id,
                            name: value,
                            quantity: 1,
                            is_in_shopping_list: true,
                            is_manually_added: true,
                        } as Food
                    ]
                }


            }

            // let product = items[props.id].foodsManually[items[props.id].foodsManually.length - 1];
            // console.log(product);
            _state.Update({
                shopping_list: {
                    foodsManually: items[props.id].foodsManually,
                    foods: _state.content.shopping_list.foods
                }
            })
            setAddItem(items);
            setInputList(undefined);

            //Add action to save items added on store
            // _dispatch(AddProductsOnShoppingList(props.id, product));
            _dispatch(AddProductsOnShoppingList(props.id, items[props.id].foodsManually));
        }
    }

    function isInObject(object: any, idToSearch: number) {
        var item = Object.values(object).find(function (e: any) {
            if (e && e.id == idToSearch) {
                return e as Food;
            }
            return undefined;
        });

        if (typeof item == "object") {
            let index = Object.values(object).indexOf(item);
            return index;
        }
        return -1;
    }

    function lastIdInObject(object: any) {
        let i = 0;
        Object.values(object).forEach((element: any) => {
            if (element.id > i) {
                i = element.id;
            }
        });

        return i;
    }


    const handleClickDeleteCard = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let foodId = Number(event.currentTarget.dataset.foodid);

        let s = _state.content.shopping_list;
        let index = isInObject(s.foodsManually, foodId);
        if (index >= 0) {
            delete s.foodsManually[index];
            s.foodsManually.splice(index, 1);
        }

        _state.Update({
            shopping_list: s
        });

        // _dispatch(RemoveProductOnShoppingList(props.id, foodId));
        _dispatch(RemoveProductOnShoppingList(props.id, s.foodsManually));

    }

    function ItemFood(food: any) {
        // const ItemFood = (props: any) => {

        if (food.initState) {
            let tmp = isInShoppingList;
            tmp[food.id] = food.food.is_in_shopping_list;
            React.useEffect(() => {
                setIsInShoppingList(tmp);
            }, []);
        }

        let stateFood = food.food;
        if (_shopping && _shopping.foods) {
            let index = isInObject(_shopping.foods, food.food.id);
            if (index >= 0) {
                stateFood = _shopping.foods[food.food.id];
            }
        }

        return (
            <div id={String(food.id)} style={{ marginTop: "2%" }}>
                <div className={food.food.is_manually_added ? "width_80" : "width_60"} style={{ padding: "2% 4%", display: "inline-flex" }}>
                    <div style={stateFood.is_in_shopping_list ? normalStyle : barredStyle}>
                        {
                            food.food.is_manually_added ?
                                <input type="text" defaultValue={food.food.name} id={String(food.id)} onKeyDown={handleKeyDown} onBlur={handleKeyDown} />
                                :
                                _strings.tr(food.food.name)
                        }
                    </div>
                    {/* {food.food.is_manually_added ?
                        <div style={{ width: '150px', textAlign: "center" }}>{food.food.quantity}</div>
                        : null
                    } */}
                </div>
                {
                    !food.food.is_manually_added ?
                        <Box className="shopping_addCardBox" sx={{
                            display: "inline-flex",
                            alignItems: "end",
                            width: "40%",
                            paddingRight: "5%",
                            justifyContent: "end",
                            "& .MuiButton-root": {
                                borderRadius: "25px"
                            },
                            "& .MuiButtonGroup-root .MuiButtonGroup-grouped": {
                                minWidth: "50px",
                                color: "#e3e1e1",
                                border: "1px solid #e3e1e1",
                                fontWeight: "bold"
                            },
                            "& .MuiButtonGroup-root .MuiButtonGroup-grouped:hover": {
                                color: "#e3e1e1",
                                border: "1px solid #e3e1e1",
                                fontWeight: "bold",
                            }
                        }}>
                            <span style={{ marginLeft: "5%" }}>
                                <IconButton size='small' data-foodid={food.food.id} data-foodqty={food.food.quantity} data-foodinlist={food.food.is_in_shopping_list} onClick={handleClickActiveCard}>
                                    {
                                        stateFood.is_in_shopping_list ?
                                            <ShoppingCartOutlinedIcon fontSize="small" sx={{ color: "#C5E29D" }} />
                                            // <img src="images/shopping_list/shopping-cart-green.png" />
                                            :
                                            <ShoppingCartOutlinedIcon fontSize="small" sx={{ color: "#FC694D" }} />
                                        // <img src="images/shopping_list/shopping-cart-orange.png" />
                                    }
                                </IconButton>
                            </span>
                        </Box>
                        :
                        <IconButton className="customButton" size='small' data-foodid={food.food.id} onClick={handleClickDeleteCard} ><DeleteOutlineIcon fontSize="small" /></IconButton>
                }
            </div>
        )
    };

    const CountBadge = styled(Badge)(
        ({ theme }) => `
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-variant: tabular-nums;
        list-style: none;
        position: relative;
        display: inline-block;
        line-height: 1;
        & .MuiBadge-badge {
          z-index: auto;
          position: absolute;
          top: 0;
          right: -15px;
          min-width: 22px;
          height: 22px;
          padding: 0 6px;
          color: #fff;
          font-weight: 600;
          font-size: 12px;
          line-height: 22px;
          white-space: nowrap;
          text-align: center;
          border-radius: 12px;
          background: #fd694d;
          box-shadow: 0px 4px 16px ${
            theme.palette.mode === 'dark' ? '#24292f' : '#bdbdbd'
          };
          transform: translate(50%, -50%);
          transform-origin: 100% 0;
        }
        `,
      );

    // const CountBadge = styled(BadgeUnstyled)(({ theme }) => ({
    //     position: "relative",
    //     boxSizing: "border-box",
    //     margin: 0,
    //     padding: 0,
    //     color: "rgba(0, 0, 0, 0.85)",
    //     fontVariant: "tabular-nums",
    //     listStyle: "none",
    //     display: "inline-block",
    //     lineHeight: 1,
    //     "& .MuiBadge-badge": {
    //         zIndex: "auto",
    //         minWidth: "20px",
    //         height: "20px",
    //         padding: "0px 6px",
    //         color: "#fff",
    //         fontWeight: 'bold',
    //         lineHeight: "20px",
    //         whiteSpace: "nowrap",
    //         textAlign: "center",
    //         background: "#fd694d",
    //         borderRadius: "10px",
    //         boxShadow: "0 0 0 1px #fff",
    //         top: "5px",
    //     },
    //     "& .MuiBadge-anchorOriginTopRightCircular": {
    //         position: "absolute",
    //         transform: "translate(50%, -50%)",
    //         transformOrigin: '100% 0',
    //     }
    // }));

    function render(): JSX.Element {
        return (
            <div style={{
                borderRadius: "2px",
                boxShadow: "0 2px 15px 1px rgba(0, 0, 0, 0.03)",
                border: "solid 1px #e3e1e1",
                marginBottom: "15px",
                padding: "0",
                width: "100%",
                height: "fit-content",
                display: "grid",
                breakInside: "avoid"
            }} >
                {
                    props.title ?
                        <div className="bold textAlign_center marginTop_5">{_strings.tr(props.title)}</div>
                        : null
                }
                {
                    props.elements.map((element, index) => (
                        <div key={index} style={{ margin: (props.title ? "5% 0" : 0) }} >
                            <div style={{ backgroundColor: "#f5f3f3", padding: "14px" }} >
                                <CountBadge badgeContent={element.foods!.length} showZero={true} >
                                    {_strings.tr(capitalizeFirstLetter(element.title))}
                                </CountBadge>
                            </div>
                            <div id={`foodList_` + props.id + index}>
                                {
                                    [element.foods].map((foodList, indexFoodList) => (
                                        foodList!.map((food: Food, indexFood) => {
                                            return (<ItemFood key={indexFood} id={food.id} food={food} initState={true}></ItemFood>)
                                        })
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
                {
                    _state.content.shopping_list && _state.content.shopping_list.foodsManually ?
                        [_state.content.shopping_list.foodsManually].map((items: Food[], index: number) => (
                            Object.entries(items).map(([index2, item]) =>
                                <ItemFood key={index2} id={item.id} food={item} />
                            )
                        ))
                        : null
                }
                {
                    inputList ? inputList : null
                }

                <div className="customButton" style={{ display: "flex", justifyContent: "center" }}>
                    <ButtonCustom style={{ width: "90%", backgroundColor: "white", color: "#8fc740", borderColor: "#8fc740" }} onClick={handleClickAddProduct} label={_strings.add_product}></ButtonCustom>
                </div>
            </div >

        )
    }

    return render();
}


export default CardShoppingList;
