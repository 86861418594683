import * as React from "react";
import useStrings from "src/localization/Localization";
import { FromStore } from "src/helpers/Store";
import { useDispatch } from "react-redux";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";

import ButtonCustom from "src/components/bases/ButtonCustom";

import { Box, InputAdornment, IconButton, OutlinedInput, FormControl, InputLabel/*, makeStyles, Theme*/ } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import MenuButton from "src/components/bases/MenuButton";
import MessagesInPage from "src/components/bases/MessagesInPage";
import Footer from "src/components/bases/Footer";
import { ApiGetUserProfile } from "src/stores/apis/user/actions";
import { ChangePassword } from "src/stores/auth/actions";

interface State {
    old_password: string,
    new_password: string,
    confirm_password: string,
    showPassword: boolean,
}

export interface AccountPageProps { }

const AccountPage: React.FunctionComponent<AccountPageProps> = (props) => {

    const _strings = useStrings();
    const _dispatch = useDispatch();

    const _user = FromStore(state => state.apis.profile.user);

    // const useStyles = makeStyles((theme: Theme) => ({
    //     button: {
    //         margin: theme.spacing(1)
    //     },
    //     leftIcon: {
    //         marginRight: theme.spacing(1)
    //     },
    //     rightIcon: {
    //         marginLeft: theme.spacing(1)
    //     },
    //     iconSmall: {
    //         fontSize: 20
    //     },
    //     root: {
    //         padding: theme.spacing(3, 2)
    //     },
    //     container: {
    //         display: "flex",
    //         flexWrap: "wrap"
    //     },
    //     textField: {
    //         marginLeft: theme.spacing(1),
    //         marginRight: theme.spacing(1),
    //         width: 400
    //     }
    // }));

    // const classes = useStyles();

    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());
    }, true);

    const [values, setValues] = React.useState<State>({
        old_password: '',
        new_password: '',
        confirm_password: '',
        showPassword: false,
    });

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    function handleSubmitChangePassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (validateResetForm()) {
            _dispatch(
                ChangePassword(values.old_password, values.new_password, _strings.confirm_password_change)
            );
        }
    }

    function validateResetForm() {
        return (
            values.new_password.length > 0 &&
            values.new_password === values.confirm_password
        );
    }

    function render(): JSX.Element {
        return (
            <div>
                <MenuButton selected={4}></MenuButton>

                <MessagesInPage></MessagesInPage>

                <div className="marginPages">
                    <Box component="form" onSubmit={handleSubmitChangePassword} >
                        <div>
                            <span>{_strings.email} : </span>
                            <span>{_user.email}</span>
                        </div>
                        <div>
                            <span>{_strings.name} : </span>
                            <span>{_user.first_name} {_user.last_name}</span>
                        </div>

                        <fieldset style={{ margin: "2%" }}>
                            <legend style={{ fontSize: "inherit" }} >{_strings.title_change_password}</legend>
                            <div>
                                <span>
                                    <FormControl sx={{ m: 1 }} variant="outlined">
                                        <InputLabel >{_strings.old_password}</InputLabel>
                                        <OutlinedInput
                                            inputProps={{
                                                'aria-label': _strings.old_password,
                                            }}
                                            required
                                            label={_strings.old_password}
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.old_password}
                                            onChange={handleChange('old_password')}
                                            autoComplete="old-password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                </span>
                            </div>

                            <div>
                                <span>
                                    <FormControl sx={{ m: 1 }} variant="outlined">
                                        <InputLabel>{_strings.new_password}</InputLabel>
                                        <OutlinedInput
                                            required
                                            label={_strings.new_password}
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.new_password}
                                            onChange={handleChange('new_password')}
                                            autoComplete="new-password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </span>

                            </div>

                            <div>
                                <FormControl sx={{ m: 1 }} variant="outlined">
                                    <InputLabel>{_strings.confirm_password}</InputLabel>
                                    <OutlinedInput
                                        required
                                        label={_strings.confirm_password}
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.confirm_password}
                                        onChange={handleChange('confirm_password')}
                                        autoComplete="new-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        </fieldset>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <ButtonCustom style={{ backgroundColor:"#8fc740" }} type="submit" disabled={!validateResetForm()} label={_strings.edit_password}></ButtonCustom>
                        </div>
                    </Box>
                </div>
                <Footer></Footer>
            </div >
        );
    }

    return render();
};

export default AccountPage;