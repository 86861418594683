import * as React from "react";

import * as DateFns from 'date-fns';
import { FromStore } from "src/helpers/Store";
import useStrings from "src/localization/Localization";
import { DeclareState } from "src/helpers/State";
import { useDispatch } from "react-redux";
import { ExecuteOnLoad } from "src/helpers/Lifecycle";

// import { Row } from "react-bootstrap";

import { Alert } from "@mui/material";
import MenuButton from "src/components/bases/MenuButton";
import Footer from "src/components/bases/Footer";
import CardShoppingList from "src/components/bases/CardShoppingList";
import { ApiGetMeals } from 'src/stores/menu/actions';
import ButtonCustom from "src/components/bases/ButtonCustom";
import { capitalizeFirstLetter, useCheckMobileScreen } from "src/helpers/fonctions";
import { ApiGetUserProfile } from "src/stores/apis/user/actions";


export interface ShoppingListPagePageProps { }

const ShoppingListPage: React.FunctionComponent<ShoppingListPagePageProps> = (props) => {
    const _strings = useStrings();
    const _dispatch = useDispatch();
    const mobileScreen = useCheckMobileScreen();
    const _menu = FromStore(state => state.menu ? state.menu.menu : null);

    const _state = DeclareState({
        menu: {
            isLoaded: false,
            menuList: new Array()
        }
    });

    const handlePrint = () => {
        window.print();
    };

    ExecuteOnLoad(() => {
        _dispatch(ApiGetUserProfile());

        let currentDateFrom = DateFns.startOfWeek(new Date(), { weekStartsOn: 1 });
        let currentDateTo = DateFns.endOfWeek(new Date(), { weekStartsOn: 1 });
        _dispatch(ApiGetMeals(DateFns.format(currentDateFrom, "yyyy-MM-dd"), DateFns.format(currentDateTo, "yyyy-MM-dd")));

        if (_menu) {
            let data = new Array();
            _menu.map((menu: any, index: any) => {
                let date = DateFns.format(new Date(menu.date), "dd/MM/yyyy");
                if (!data[date]) {
                    data[date] = new Array();
                }
                let tmp = {
                    title: _strings.tr(menu.meal.toLocaleLowerCase()), //menu.date,
                    foods: new Array()
                };
                menu.elements.map((food: any, indexFood: number) => {
                    tmp.foods.push({
                        id: food.food_id,
                        name: capitalizeFirstLetter(food.food_name),
                        quantity: food.quantity,
                        is_in_shopping_list: food.is_in_shopping_list
                    });
                })
                data[date].push(tmp);
                // data.push(tmp);
            })

            _state.Update({
                menu: {
                    isLoaded: true,
                    menuList: data
                }
            });
        }

    }, true);

    function render(): JSX.Element {
        // let marche = [
        //     {
        //         title: "Boucher",
        //         foods: [
        //             { id: 1, name: "Steack haché", quantity: 500 }
        //         ]
        //     }
        // ];

        // let cremerie = [
        //     {
        //         title: "Beurres",
        //         foods: [
        //             { id: 2, name: "Beurre", quantity: 500 },
        //             { id: 3, name: "Beurre salé", quantity: 500 }
        //         ]
        //     },
        //     {
        //         title: "Yaourts",
        //         foods: [
        //             { id: 4, name: "Danette vanille", quantity: 500 },
        //             { id: 5, name: "Yaourt aux fruits", quantity: 500 }
        //         ]
        //     }
        // ];

        // let autres = [
        //     {
        //         title: "23/11/2021",
        //         foods: [
        //             { id: 8, name: "Poulet basquaise", quantity: 2, is_in_shopping_list: true },
        //             { id: 9, name: "Pain complet", quantity: 2, is_in_shopping_list: true },
        //             { id: 10, name: "Nectarines", quantity: 2, is_in_shopping_list: true },
        //         ]
        //     }
        // ];

        return (
            <div>
                <MenuButton selected={3}></MenuButton>

                <div className="marginPages">
                    <div>
                        <div className="shopping_preference_title">{_strings.shopping_list}</div>

                        {
                            (_menu && _menu.length == 0) ?
                                <div style={{ margin: "35px 10%" }}>
                                    <Alert severity="info">{_strings.shopping_list_unavailable}</Alert>
                                </div>
                                :
                                <div style={{ justifyContent: "space-between", display: "block", columnCount: (mobileScreen ? 1 : 3) }} >
                                    {
                                        [_state.content.menu.menuList].map((menu, index) => (
                                            Object.entries(menu).map((list, indexM) => {
                                                const splitDate = list[0].split('/');
                                                const d = new Date(splitDate[2]+'-'+splitDate[1]+'-'+splitDate[0]);
                                                let dayNumber = d.getDay();
                                                if(dayNumber === 0 ) {
                                                    dayNumber = 7;
                                                }
                                                return(<CardShoppingList key={indexM} id={dayNumber} title={list[0]} elements={list[1]}></CardShoppingList>)
                                            })
                                        ))
                                    }
                                    {/* <CardShoppingList title="Marché" elements={marche}></CardShoppingList>
                            <CardShoppingList title="Crémerie" elements={cremerie}></CardShoppingList>
                            <CardShoppingList elements={autres}></CardShoppingList> */}

                                </div>
                        }
                    </div>

                    <div className="marginPages customButton" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonCustom style={{ backgroundColor: "white", color: "#8fc740", borderColor: "#8fc740" }} label={_strings.print} onClick={handlePrint}></ButtonCustom>
                    </div>
                </div >

                <Footer></Footer>
            </div >
        );
    }

    return render();
};

export default ShoppingListPage;